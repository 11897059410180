import React from "react"
import Layout from "../zzz/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../zzz/organisms/heading"
import styled from "styled-components"
import Inner from "../zzz/layout/pageInner/Inner"
import SEO from "../components/SEO/seo"
import { getImage } from "../lib/util"

import ImageOne from "../images/Mattress-Firm.gif"
import ImageTwo from "../images/Mattress-Medium.gif"
import ImageThree from "../images/Mattress-Plush.gif"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ComfortLevelPage = ({ location }) => {
  const {
    page: {
      seo,
      title,
      pageId,
      heading,
      content,
      featuredImage,
      acfComfortLevels: { comfortLevels },
    },
  } = useStaticQuery(PAGE_QUERY)

  // const { file: imgFirm } = useStaticQuery(IMAGE_ONE)
  // const { file: imgMedium } = useStaticQuery(IMAGE_TWO)
  // const { file: imgPlush } = useStaticQuery(IMAGE_THREE)

  return (
    <Layout location={location}>
      <SEO title={title} location={location} post_id={pageId} seo={seo} />
      <Heading
        location={location}
        subTitle={heading.subTitle}
        image={getImage(featuredImage)}
      />
      <Inner>
        <TitleBox>
          <h2>The Different Mattress Types</h2>
        </TitleBox>
        <LevelsWrapper>
          {comfortLevels.map((level) => {
            let bedImage
            if (level.title === "firm") {
              bedImage = ImageOne
            }
            if (level.title === "medium") {
              bedImage = ImageTwo
            }
            if (level.title === "plush") {
              bedImage = ImageThree
            }
            return (
              <div key={level.title}>
                <img src={bedImage} />
                <h2>{level.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: level.description }} />
              </div>
            )
          })}
        </LevelsWrapper>
      </Inner>
      <ContentWrapper>
        <Inner>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Inner>
      </ContentWrapper>
    </Layout>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const LevelsWrapper = styled.div`
  padding: 60px 0;
  h2 {
    margin: 1rem 0 0.5rem 0;
    text-transform: capitalize;
  }
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: 1fr;
  }
`
const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.tusk["000"]};
  padding: 60px 0;
  h2 {
    margin: 1rem 0 0.5rem 0;
    text-transform: capitalize;
  }
  ul {
    list-style-type: disc;
    margin: 1rem;
  }
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  h2,
  p {
    text-align: center;
    color: #223247;
    margin-top: 10px;
  }
  h2 {
    border-bottom: 3px solid red;
  }
`

// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================

const PAGE_QUERY = graphql`
  {
    page: localWpGraphQlPages(
      slug: { eq: "the-ultimate-guide-to-mattress-firmness" }
    ) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      pageId
      content
      heading {
        subTitle
      }
      featuredImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      acfComfortLevels {
        comfortLevels {
          description
          title
        }
      }
    }
  }
`

// const IMAGE_ONE = graphql`
//   {
//     file(relativePath: { eq: "Mattress-Firm.gif" }) {
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//   }
// `

// const IMAGE_TWO = graphql`
//   {
//     file(relativePath: { eq: "Mattress-Medium.gif" }) {
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//   }
// `

// const IMAGE_THREE = graphql`
//   {
//     file(relativePath: { eq: "Mattress-Plush.gif" }) {
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//   }
// `

export default ComfortLevelPage
